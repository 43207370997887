<template>
  <section id="AllegiantStadiumFieldView">
    <EventSpacesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
import EventSpacesCarousel from "@/components/global/EventSpacesCarousel";
import { getFieldViewImages } from "@/data/sectionData/eventSpaces/AllegiantStadium";

export default {
  name: "AllegiantStadiumFieldView",
  components: {
    EventSpacesCarousel,
  },
  data() {
    return {
      carouselImages: getFieldViewImages(),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>